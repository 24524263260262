<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { mapGetters } from 'vuex';
import { updateRecordV2 } from '@/services/api/v2/records.v2.api';

export default {
  name: 'StockManagementLite',
  components: { ModuleNavigationBar },
  data: () => {
    return {
      moduleStatus: 'loading',
      categoryTab: 0,
      categories: [],
      articles: [],
      articleTableId: null,
      articleStepSize: {},
      articleCounter: {},
      articleHeaders: [
        {
          text: 'Nr.',
          value: 'number',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: '',
          value: 'warning',
        },
        {
          text: 'Count',
          value: 'stock_counter',
        },
        {
          text: '',
          value: 'actions',
        },
        {
          text: '',
          width: 200,
          value: 'loader',
        },
      ],
      btnState: 'remove',
      tableKey: 0, // This is the key to force re-render
      timerRemaining: {}, // Track the remaining time for each item
      loadingRows: {}, // Track loading state for each row
      timeouts: {}, // Track timeouts for each row
      intervals: {}, // Track intervals for each row
    };
  },
  computed: {
    ...mapGetters(['project']),

    focusedCategory() {
      return this.categories[this.categoryTab] ?? null;
    },

    stockDifference() {
      return (item) => {
        const originalCount = item.stock_counter;
        const currentCount = this.articleCounter[item.id] || 0;
        return currentCount - originalCount;
      };
    },
  },

  watch: {
    async focusedCategory(value) {
      if (value) {
        const { articles } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_STOCK_ARTICLES',
              project: this.project.id,
              as: 'articles',
              sortBy: 'number',
              columns: [
                {
                  name: 'category',
                  conditions: [
                    {
                      operator: '=',
                      value: value,
                    },
                  ],
                },
                ...[
                  'number',
                  'title',
                  'stock_counter',
                  'step_small',
                  'step_small_title',
                  'step_large',
                  'step_large_title',
                  'min',
                  'max',
                  'delivery_time',
                  'category',
                ].map((key) => ({ name: key })),
              ],
            },
          ],
        });

        this.articles = articles.records;
        this.articleTableId = articles.id;

        this.articleStepSize = {};
        this.articles.forEach((a) => {
          this.articleStepSize[a.id] = a.step_small;
          this.articleCounter[a.id] = a.stock_counter;
          this.$set(this.loadingRows, a.id, false); // Set loading to false initially
        });
      }
    },
  },
  async mounted() {
    const { categories } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_STOCK_ARTICLES',
          project: this.project.id,
          as: 'categories',
          sortBy: 'category',
          columns: [
            {
              name: 'category',
            },
          ],
        },
      ],
    });

    this.categories = [...new Set(categories.records.map((x) => x.category))];
  },
  methods: {
    async updateStockCounter(record, count) {
      count = parseInt(count);
      if (isNaN(count)) return;

      const newCount =
        this.btnState === 'remove'
          ? this.articleCounter[record.id] - count
          : this.articleCounter[record.id] + count;

      this.$set(this.articleCounter, record.id, newCount);
      this.tableKey += 1;

      // Clear any existing timeouts and intervals for the specific row
      if (this.timeouts[record.id]) {
        clearTimeout(this.timeouts[record.id]);
      }
      if (this.intervals[record.id]) {
        clearInterval(this.intervals[record.id]);
      }

      this.$set(this.timerRemaining, record.id, 5);

      // Update the timer countdown every second
      this.intervals[record.id] = setInterval(() => {
        if (this.timerRemaining[record.id] > 0) {
          this.$set(
            this.timerRemaining,
            record.id,
            this.timerRemaining[record.id] - 1
          );
        }
      }, 1000);

      // Start a new timeout to save the record after 5 seconds
      this.timeouts[record.id] = setTimeout(async () => {
        clearInterval(this.intervals[record.id]); // Clear the interval when the timer finishes
        await this.saveRecord(record);
      }, 5000);
    },

    async saveRecord(record) {
      this.$set(this.loadingRows, record.id, true); // Set loading to true

      const updatedRecord = await updateRecordV2(
        this.articleTableId,
        record.id,
        {
          stock_counter: this.articleCounter[record.id],
        }
      );
      record.stock_counter = updatedRecord.stock_counter;

      this.$set(this.loadingRows, record.id, false); // Set loading to false after save
      this.$set(this.timerRemaining, record.id, null); // Clear the timer
    },

    forceSave(record) {
      if (this.timeouts[record.id]) {
        clearTimeout(this.timeouts[record.id]); // Clear the existing timeout
      }
      if (this.intervals[record.id]) {
        clearInterval(this.intervals[record.id]); // Clear the interval
      }
      this.saveRecord(record); // Save the record immediately
    },

    getStockDifferenceColor(difference) {
      return difference > 0 ? 'green' : difference < 0 ? 'red' : '';
    },
  },
};
</script>

<template>
  <div class="stock-management-container">
    <module-navigation-bar title="Stock Management Lite">
      <template #module-nav-actions>
        <v-tabs
          v-model="categoryTab"
          height="46"
          style="width: auto; max-width: calc(100vw - 400px)"
          background-color="transparent"
          show-arrows
        >
          <v-tab v-for="item in categories" :key="item">{{ item }} </v-tab>
        </v-tabs>
      </template>
    </module-navigation-bar>

    <div v-if="focusedCategory" class="table-wrapper">
      <v-data-table
        :key="tableKey"
        :headers="articleHeaders"
        :items="articles"
        class="full-width-table"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-toolbar-title>Articles</v-toolbar-title>
            <v-spacer />
            <v-btn-toggle
              v-model="btnState"
              mandatory
              group
              :color="btnState === 'remove' ? 'error' : 'success'"
            >
              <v-btn small :value="'remove'"
                ><v-icon>mdi-minus</v-icon> Remove
              </v-btn>
              <v-btn small :value="'add'"><v-icon>mdi-plus</v-icon> Add </v-btn>
            </v-btn-toggle>
          </v-toolbar>
        </template>

        <template #item.stock_counter="{ item }">
          {{ item.stock_counter }}
          <span
            v-if="stockDifference(item) !== 0"
            :key="`${item.id}-${articleCounter[item.id]}`"
            :style="{ color: getStockDifferenceColor(stockDifference(item)) }"
          >
            ({{ stockDifference(item) > 0 ? '+' : ''
            }}{{ stockDifference(item) }})
          </span>
        </template>

        <template #item.warning="{ item }">
          <v-tooltip v-if="item.stock_counter < item.min" right>
            <template #activator="{ on, attrs }">
              <v-icon color="warning" large v-bind="attrs" v-on="on">
                mdi-alert
              </v-icon>
            </template>
            <span>Stock is below minimum value {{ item.min }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.stock_counter > item.max" right>
            <template #activator="{ on, attrs }">
              <v-icon color="warning" large v-bind="attrs" v-on="on">
                mdi-alert
              </v-icon>
            </template>
            <span>Stock is above maximum value {{ item.max }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex align-center">
            <v-spacer />
            <v-text-field
              v-model="articleStepSize[item.id]"
              filled
              class="ml-2"
              dense
              hide-details
              style="max-width: 100px"
              type="number"
              :disabled="loadingRows[item.id]"
              @input="tableKey += 1"
            ></v-text-field>

            <v-btn
              class="ml-5"
              elevation="0"
              :color="btnState === 'remove' ? 'error' : 'success'"
              :loading="loadingRows[item.id]"
              :disabled="loadingRows[item.id]"
              @click="updateStockCounter(item, articleStepSize[item.id])"
            >
              <v-icon class="mr-2">{{
                btnState === 'remove' ? 'mdi-minus' : 'mdi-plus'
              }}</v-icon>
              {{ articleStepSize[item.id] }} {{ item.step_small_title }}
            </v-btn>

            <v-btn
              class="ml-2"
              elevation="0"
              :color="btnState === 'remove' ? 'error' : 'success'"
              :disabled="
                loadingRows[item.id] ||
                (btnState === 'remove' && item.stock_counter < item.step_large)
              "
              :loading="loadingRows[item.id]"
              @click="updateStockCounter(item, item.step_large)"
            >
              <v-icon class="mr-2">{{
                btnState === 'remove' ? 'mdi-minus' : 'mdi-plus'
              }}</v-icon>
              1 {{ item.step_large_title }} ({{ item.step_large }})
            </v-btn>
          </div>
        </template>
        <template #item.loader="{ item }">
          <v-btn
            v-if="timerRemaining[item.id] && timerRemaining[item.id] >= 0"
            elevation="0"
            color="info"
            :loading="loadingRows[item.id]"
            :disabled="loadingRows[item.id]"
            @click="forceSave(item)"
          >
            Save Now ({{ timerRemaining[item.id] }}s)
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stock-management-container {
  background-color: #f5f5f5;
}

.full-width-table {
  width: 100%;
}

.table-wrapper {
  margin: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
